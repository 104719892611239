import React from "react";
import SearchInputBox from "../ReusableCoponents/InputBox/SearchInputBox";
import styles from "./ArtistsFilter.module.scss";
import { upArrowIcon } from "../assets";
import InputCheckbox from "../ReusableCoponents/InputBox/InputCheckbox";
import PriceInputBox from "../ReusableCoponents/InputBox/PriceInputBox";
import Button from "../ReusableCoponents/Button";


const ShopAppointmentFilter = () => {
  return (
    <div className={styles.filterContainer}>
      <h4>Filter</h4>

      <div className={styles.experienceContainer}>
        <div className={styles.experienceGroup}>
          <h6 className={styles.subHeading}>Experience</h6>
          <img src={upArrowIcon} alt="" />
        </div>

        <div className={styles.checkboxContainer}>
          <InputCheckbox option="Less than 1 year" />
          <InputCheckbox option="1 to 2 years" />
          <InputCheckbox option="2 to 5 years" />
          <InputCheckbox option="Above 5 years" />
        </div>
      </div>

      <div className={styles.filterOptionsContainer}>
        <div className={styles.locationContainer}>
          <h6 className={styles.subHeading}>Location</h6>
          <SearchInputBox />
        </div>

        <div className={styles.rangeOfAppointmentsContainer}>
          <h6 className={styles.subHeading}>Range of appointments</h6>
          <div className={styles.rangeOptionContainer}>
            <PriceInputBox name="10" minMax="Min" />
            <PriceInputBox name="100" minMax="Max" />
            <button className={styles.goButton}>Go</button>
          </div>
        </div>

        <div className={styles.earningsContainer}>
          <h6 className={styles.subHeading}>Total Earnings</h6>
          <div className={styles.earningsOptionContainer}>
            <PriceInputBox minMax="Min" />
            <PriceInputBox minMax="Max" />
            <button className={styles.goButton}>Go</button>
          </div>
        </div>

        <div className={styles.reviewContainer}>
          <div className={styles.reviewGroup}>
            <h6 className={styles.subHeading}>Review Range</h6>
            <img src={upArrowIcon} alt="" />
          </div>

          <div className={styles.checkboxContainer}>
            <InputCheckbox option="All" />
            <InputCheckbox option="1 Star" />
            <InputCheckbox option="2 Star" />
            <InputCheckbox option="3 Star" />
            <InputCheckbox option="4 Star" />
            <InputCheckbox option="5 Star" />
          </div>
        </div>

        <div className={styles.genderContainer}>
          <h6 className={styles.subHeading}>Gender</h6>
          <div className={styles.checkboxContainer}>
            <InputCheckbox option="Both" />
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button title="Apply" color="primary" />
          <Button title="Cancel" color="secondary" />
        </div>
      </div>
    </div>
  );
};

export default ShopAppointmentFilter;
