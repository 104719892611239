import instance from "../axiosInstance";

export const loginService = async (data) => {
    try {
        const response = await instance.post('/admin/auth/login', data);
        return response.data;
    } catch (error) {
        console.error('Error fetching customers:', error);
        return error;
    }
}