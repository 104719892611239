import React from "react";
import AdminHeader from "../../layout/adminheader/AdminHeader";
import styles from "./shopServices.module.scss";
import { services } from "../../constants/ServicesConstants";
import PackageCard from "./PackageCard";

const ShopServices = () => {
  return (
    <div className="middleData">
      <AdminHeader title="ABC Barber Shop" />
      <div className={styles.container}>
        <div className={styles.leftCard}>
          <span>Services</span>
          <div>
            {services.map((service) => {
              return <div className={styles.service}>{service}</div>;
            })}
          </div>
        </div>
        <div className={styles.rightCard}>
          <span>Packages</span>
          <div className={styles.allCards}>
          <PackageCard />
          <PackageCard />
          <PackageCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopServices;
