import React from 'react'

const EmailSVGIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.675 10.1L9.35714 6.2M5.64286 6.2L1.32502 10.1M1 2.95L6.3072 6.66504C6.73696 6.96587 6.95184 7.11629 7.18557 7.17455C7.39203 7.22601 7.60797 7.22601 7.81443 7.17455C8.04816 7.11629 8.26304 6.96587 8.6928 6.66504L14 2.95M4.12 11.4H10.88C11.9721 11.4 12.5182 11.4 12.9353 11.1875C13.3022 11.0005 13.6005 10.7022 13.7875 10.3353C14 9.91815 14 9.3721 14 8.28V4.12C14 3.0279 14 2.48185 13.7875 2.06472C13.6005 1.6978 13.3022 1.39949 12.9353 1.21254C12.5182 1 11.9721 1 10.88 1H4.12C3.0279 1 2.48185 1 2.06472 1.21254C1.6978 1.39949 1.39949 1.6978 1.21254 2.06472C1 2.48185 1 3.0279 1 4.12V8.28C1 9.3721 1 9.91815 1.21254 10.3353C1.39949 10.7022 1.6978 11.0005 2.06472 11.1875C2.48185 11.4 3.0279 11.4 4.12 11.4Z" stroke="#3B7358" strokeLinecap="round" strokeLinejoin="round" />
            <path opacity="0.12" d="M8.6928 6.66504C8.26304 6.96587 8.04816 7.11629 7.81443 7.17455C7.60797 7.22602 7.39203 7.22602 7.18557 7.17455C6.95184 7.11629 6.73696 6.96587 6.3072 6.66504L1 2.95C1 1.87304 1.87304 1 2.95 1H12.05C13.127 1 14 1.87304 14 2.95L8.6928 6.66504Z" fill="#3B7358" />
        </svg>

    )
}

export default EmailSVGIcon
