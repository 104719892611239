import React from "react";
import StarRating from "../ReusableCoponents/StarRating";
import './Table.scss'
import PageHeader from "../layout/pageHeader/PageHeader";


function CustomerTable({ customerColumnName, customerList }) {
  return (
    <div className="DBdataGridBox">
      <PageHeader/>
      <table className="DBdataGrid">
        <thead>
          <tr>
            {customerColumnName.map((col) => {
              return <th key={col.id}>{col.name}</th>;
            })}
          </tr>
        </thead>

        <tbody>
          {customerList.map((list) => {
            return (
              <tr key={list.id}>
                <td>{list.id}</td>
                <td>{list.name}</td>
                <td>{list.address}</td>
                <td>{list.email}</td>
                <td>{list.phone}</td>
                <td className="noAppointment">{list.appointments}</td>
                <td>{<StarRating />}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default CustomerTable;
