import React from 'react';
import { Bar } from 'react-chartjs-2';
import {data, options} from '../../constants/BarChartConstants'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = () => {
  return <Bar data={data} options={options} />;
};

export default BarChart;
