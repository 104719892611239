import React from "react";
import styles from "./ShopDashboardPage.module.scss";
import { shopOwnerImage, shopOwnerCallIcon, shopOwnerCategoryIcon, shopOwnerLocationIcon,shopOwnerMailIcon } from "../../assets/";

const OwnerProfile = () => {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={shopOwnerImage} alt="" />
      </div>

      <div className={styles.ownerDetails}>
        <h2>Ram Kumar</h2>
        <div className={styles.social}>
          <img src={shopOwnerMailIcon} alt="" />
          <span>abc@gmail.com</span>
        </div>
        <div className={styles.social}>
          <img src={shopOwnerCallIcon} alt="" />
          <span>+91 9876543210</span>
        </div>
        <div className={styles.social}>
          <img src={shopOwnerCategoryIcon} alt="" />
          <span>Male</span>
        </div>
        <div className={styles.social}>
          <img src={shopOwnerLocationIcon} alt="" />
          <span>11-a Jaipur Rajasthan</span>
        </div>
      </div>
    </div>
  );
};

export default OwnerProfile;
