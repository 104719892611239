export const appointmentList = [
    {
        id: "1",
        name: "Ram Kumar",
        service: "Haircut",
        status: "Cancelled",
    },
    {
        id: "2",
        name: "Ram Kumar",
        service: "Shaving",
        status: "In Process",
    },
    {
        id: "3",
        name: "Ram Kumar",
        service: "Makeup",
        status: "Cancelled",
    },
    {
        id: "4",
        name: "Ram Kumar",
        service: "Hairstyle",
        status: "In Process",
    },
    {
        id: "5",
        name: "Ram Kumar",
        service: "Waxing",
        status: "Completed",
    },
    {
        id: "6",
        name: "Ram Kumar",
        service: "Makeup",
        status: "Completed",
    },
    {
        id: "7",
        name: "Ram Kumar",
        service: "Hair Spa",
        status: "Cancelled",
    },
    {
        id: "8",
        name: "Ram Kumar",
        service: "Haircut",
        status: "Cancelled",
    },
    {
        id: "9",
        name: "Ram Kumar",
        service: "Massage",
        status: "Completed",
    },
    {
        id: "10",
        name: "Ram Kumar",
        service: "Hair Spa",
        status: "Completed",
    },
    {
        id: "11",
        name: "Ram Kumar",
        service: "Makeup",
        status: "Completed",
    },
]

export const appointmentListColumnName = [
    {
        id: "1",
        name: "Sr.No."
    },
    {
        id: "2",
        name: "Name"
    },
    {
        id: "3",
        name: "Services"
    },
    {
        id: "4",
        name: "Status"
    }
]