import React from 'react'

function InfiniteSlider({ images, speed,setSpeed }) {
  return (
    <div className="inner" >
      <div className="wrapper">
        <section style={{ "--speed": `${speed}ms` }} >
          {images.map(({ id, image }) => (
            <div className="image" key={id} onMouseOver={()=> setSpeed(0)} onMouseOut={()=> setSpeed(10000)}>
              <img src={image} alt={id} />
            </div>
          ))}
        </section>
      </div>
    </div>
  )
}

export default InfiniteSlider