import React from "react";
import SearchInputBox from "../ReusableCoponents/InputBox/SearchInputBox";
import styles from "./CustomerFilter.module.scss";
import { upArrowIcon } from "../assets";
import InputCheckbox from "../ReusableCoponents/InputBox/InputCheckbox";
import PriceInputBox from "../ReusableCoponents/InputBox/PriceInputBox";
import Button from "../ReusableCoponents/Button";


const CustomerFilter = () => {
  return (
    <div className={styles.filterContainer}>
      <h4>Filter</h4>
      <div className={styles.filterOptionsContainer}>
        <div className={styles.locationContainer}>
          <h6 className={styles.subHeading}>Location</h6>
          <SearchInputBox />
        </div>

        <div className={styles.appointmentExpenseContainer}>
          <h6 className={styles.subHeading}>One Time Appointment Expenses</h6>
          <div className={styles.appointmentExpenseOptionContainer}>
            <PriceInputBox name="1000" minMax="Min" />
            <PriceInputBox name="10000" minMax="Max" />
            <button className={styles.goButton}>Go</button>
          </div>
        </div>

        <div className={styles.serviceContainer}>
          <div className={styles.serviceGroup}>
            <h6 className={styles.subHeading}>Services</h6>
            <img src={upArrowIcon} alt="" />
          </div>

          <div className={styles.checkboxContainer}>
            <InputCheckbox option="Hair Styling" />
            <InputCheckbox option="Makeup" />
            <InputCheckbox option="Grooming" />
            <InputCheckbox option="Spa" />
            <InputCheckbox option="Massage" />
            <InputCheckbox option="Other" />
          </div>
        </div>

        <div className={styles.frequencyContainer}>
          <div className={styles.serviceGroup}>
            <h6 className={styles.subHeading}>Frequency</h6>
            <img src={upArrowIcon} alt="" />
          </div>

          <div className={styles.checkboxContainer}>
            <InputCheckbox option="10 Days or less" />
            <InputCheckbox option="15 to 30 Days" />
            <InputCheckbox option="1 to 2 months" />
            <InputCheckbox option="More than 2 months" />
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button title="Apply" color="primary" />
          <Button title="Cancel" color="secondary" />
        </div>

      </div>
    </div>
  )
}

export default CustomerFilter
