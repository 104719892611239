import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomePage from '../pages/BussinessPages/HomePage';
import Dashboard from '../pages/AdminPanelPages/dashboard/Dashboard';
import LoginPage from '../auth/LoginPage';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import MainPage from '../pages/AdminPanelPages/mainPage/MainPage';
import ShopProfile from '../profilePages/shopProfile/ShopProfile';
import Artist from '../pages/AdminPanelPages/artists/Artist';
import Branch from '../pages/AdminPanelPages/branches/Branch';
import Package from '../pages/AdminPanelPages/package/Package';
import EditProfile from '../profilePages/shopProfile/EditProfile';
import Shops from '../pages/AdminPanelPages/shops/Shops';
import Customers from '../pages/AdminPanelPages/customers/Customers';
import ForgotPage from '../auth/ForgotPage'
import VerificationPage from '../auth/VerificationPage'
import ResetPasswordPage from '../auth/ResetPasswordPage';
import FinishPage from '../auth/FinishPage';
import ShopDashboard from '../subpages/shopDashboard/ShopDashboard'
import ShopBranches from '../subpages/shopBranches/ShopBranches'
import ShopAppointments from '../subpages/shopAppointments/ShopAppointments'
import ShopServices from '../subpages/shopServices/ShopServices'
import ShopArtists from '../subpages/shopArtists/ShopArtists';
import ShopCustomers from '../subpages/shopCustomers/ShopCustomers';
import ShopReviews from '../subpages/shopReviews/ShopReviews';


function Index() {

    const bussinessPages = [
        {
            id: 1,
            path: "/",
            element: <HomePage />
        },
    ]

    const adminPanelPublicPages = [
        {
            id: 1,
            path: "/admin/login",
            element: <LoginPage />
        },
        {
            id: 2,
            path: "/admin/forgot",
            element: <ForgotPage />
        },
        {
            id: 3,
            path: "/admin/verification",
            element: <VerificationPage />
        },
        {
            id: 4,
            path: "/admin/resetPassword",
            element: <ResetPasswordPage />
        },
        {
            id: 5,
            path: "/admin/finishPage",
            element: <FinishPage />
        },

    ]

    const adminPanelPrivatePages = [
        {
            id: 1,
            path: "/admin/dashboard",
            element: <Dashboard />
        },
        {
            id: 2,
            path: "/admin/profiles",
            element: <ShopProfile />
        },
        {
            id: 3,
            path: "/admin/artists",
            element: <Artist />
        },
        {
            id: 4,
            path: "/admin/branches",
            element: <Branch />
        },
        {
            id: 5,
            path: "/admin/packages",
            element: <Package />
        },
        {
            id: 6,
            path: "/admin/shop/editProfile",
            element: <EditProfile />
        },
        {
            id: 7,
            path: "/admin/shops",
            element: <Shops />
        },
        {
            id: 8,
            path: "/admin/customers",
            element: <Customers />
        },
        {
            id: 9,
            path: "/admin/artists",
            element: <Artist />
        },
        {
            id: 10,
            path: "/admin/shops/shopDashboard/:id",
            element: <ShopDashboard />
        },
        {
            id: 11,
            path: "/admin/shops/shopBranches/:ownerId",
            element: <ShopBranches />
        },
        {
            id: 12,
            path: "/admin/shops/shopAppointments",
            element: <ShopAppointments />
        },
        {
            id: 13,
            path: "/admin/shops/shopServices",
            element: <ShopServices />
        },
        {
            id: 14,
            path: "/admin/shops/shopArtists",
            element: <ShopArtists />
        },
        {
            id: 15,
            path: "/admin/shops/shopCustomers",
            element: <ShopCustomers />
        },
        {
            id: 16,
            path: "/admin/shops/shopReviews",
            element: <ShopReviews />
        },

    ]

    return (
        <BrowserRouter >
            <Routes>

                {bussinessPages.map((page) => {
                    return (
                        <Route
                            key={page.id}
                            path={page.path}
                            element={page.element}
                        />
                    )
                })}


                {adminPanelPublicPages.map((page) => {
                    return (
                        <Route
                            key={page.id}
                            path={page.path}
                            element={<PublicRoute>{page.element}</PublicRoute>}
                        />
                    )
                })}

                <Route
                    path='/admin'
                    element={<PrivateRoute><MainPage /></PrivateRoute>}
                >
                    {adminPanelPrivatePages.map((page) => {
                        return (
                            <Route
                                key={page.id}
                                path={page.path}
                                element={page.element}
                            />
                        )
                    })}

                </Route>
            </Routes>

        </BrowserRouter>
    )

}

export default Index