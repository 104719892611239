import React from "react";
import styles from "./Login.module.scss";
import { Link } from "react-router-dom";
import resetPasswordImage from "../assets/images/resetPasswordImage.png";

const FinishPage = () => {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.finishInnerContainer}>
        <img src={resetPasswordImage} alt="" />
        <h6>Password Reset Successfully!</h6>
        <div className={styles.finishButtonContainer}>
          <Link to="/admin/login" className={styles.primaryBtn}>
            Back To Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FinishPage;
