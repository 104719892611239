import React from 'react'

const MenuSVGIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.59922 24C1.93255 24 1.36615 23.7664 0.900019 23.2992C0.432819 22.8331 0.199219 22.2667 0.199219 21.6C0.199219 20.9333 0.432819 20.3669 0.900019 19.9008C1.36615 19.4336 1.93255 19.2 2.59922 19.2C3.26589 19.2 3.83228 19.4336 4.29842 19.9008C4.76562 20.3669 4.99922 20.9333 4.99922 21.6C4.99922 22.2667 4.76562 22.8331 4.29842 23.2992C3.83228 23.7664 3.26589 24 2.59922 24ZM8.19922 23.2V20H28.9992V23.2H8.19922ZM2.59922 14.4C1.93255 14.4 1.36615 14.1664 0.900019 13.6992C0.432819 13.2331 0.199219 12.6667 0.199219 12C0.199219 11.3333 0.432819 10.7669 0.900019 10.3008C1.36615 9.8336 1.93255 9.6 2.59922 9.6C3.26589 9.6 3.83228 9.8336 4.29842 10.3008C4.76562 10.7669 4.99922 11.3333 4.99922 12C4.99922 12.6667 4.76562 13.2331 4.29842 13.6992C3.83228 14.1664 3.26589 14.4 2.59922 14.4ZM8.19922 13.6V10.4H28.9992V13.6H8.19922ZM2.59922 4.8C1.93255 4.8 1.36615 4.5664 0.900019 4.0992C0.432819 3.63307 0.199219 3.06667 0.199219 2.4C0.199219 1.73333 0.432819 1.16693 0.900019 0.7008C1.36615 0.2336 1.93255 0 2.59922 0C3.26589 0 3.83228 0.2336 4.29842 0.7008C4.76562 1.16693 4.99922 1.73333 4.99922 2.4C4.99922 3.06667 4.76562 3.63307 4.29842 4.0992C3.83228 4.5664 3.26589 4.8 2.59922 4.8ZM8.19922 4V0.8H28.9992V4H8.19922Z" fill="black" />
        </svg>

    )
}

export default MenuSVGIcon
