import React from 'react'
import AdminHeader from '../../../layout/adminheader/AdminHeader'
import './Customers.scss'
import { customerColumnName, customerList } from '../../../constants/CustomerConstants';
import CustomerTable from '../../../tables/CutomerTable'


const Customers = () => {


  return (
    <div className="middleData">
      <AdminHeader title="Customers" />
        <CustomerTable customerColumnName={customerColumnName} customerList={customerList} />
    </div>
  )
}

export default Customers
