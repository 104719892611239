import React from "react";
import "./LeftsidebarStyle.scss";
import { logoNew } from "../../assets";
import { Link, useLocation, useParams } from "react-router-dom";
import Menu from "./Menu";
import SubMenu from "../../subpages/leftSideBar/SubMenu";

function Leftsidebar() {

  const location = useLocation()
  const params = useParams()
  // console.log("params",params)
  // console.log(location)

  return (
    <div className="dashboardLeft">
      <div className="logo">
        <Link to="/">
          <img src={logoNew} alt="error" />
        </Link>
      </div>
      <Menu />

     {location.pathname === `/admin/shops/shopDashboard/${params.id}` && <SubMenu /> }    
    </div>
  );
}

export default Leftsidebar;
