import React, { useEffect, useState } from 'react'
import { shopBranchTableColumnName } from '../constants/ShopBranchConstants';
import style from '../subpages/shopBranches/shopBranch.module.scss'
import Modal from '../modals/Modal';
import { useNavigate, useParams } from 'react-router-dom';
import { getShopsByOwnerProfileId } from '../apiServices/Shop/ShopApiServices';
import ShopBranchesPhotos from '../subpages/shopBranches/ShopBranchesPhotos';
import ShopModal from '../modals/ShopModal/ShopModal';

const ShopBranchTable = () => {

  const navigate = useNavigate()

  const [modal, setModal] = useState(false)
  const [shopData, setShopData] = useState(null)
  const [shopList, setShopList] = useState([])

  const { ownerId } = useParams()

  useEffect(() => {
    const fetchShops = async () => {
      try {
        const result = await getShopsByOwnerProfileId(ownerId)  
        console.log("result 222", result)
        setShopList(result?.data)
      } catch (error) {
        console.error('Failed to fetch shops: ', error)
      }
    }
    fetchShops()
  }, [ownerId])

  const handleClick = () => {
    // setShopData(list)
    navigate(`/admin/shops/shopDashboard/${ownerId}`)
  }

  const handleViewButton=(e, list)=>{
    e.stopPropagation()
    setModal(true)
    setShopData(list)
  }

  const photoClickHandler = (e)=>{
    e.stopPropagation()
  }

  return (
    <div className={style.container}>
      <div className="DBdataGridBox">
        <table className="DBdataGrid">
          <thead>
            <tr>
              {shopBranchTableColumnName.map((col, index) => {
                return <th id={col.id} key={index}>{col.name}</th>;
              })}
            </tr>
          </thead>

          <tbody>
            {shopList?.map((list, index) => {
              return (
                <tr key={index} onClick={handleClick}>
                  <td onClick={photoClickHandler}> <ShopBranchesPhotos /> </td>
                  <td>{list?.shopName}</td>
                  <td>{list?.authId?.email}</td>
                  <td>{list?.authId?.mobileNumber || 9876543210}</td>
                  <td>{`${list?.address?.street} ${list?.address?.city} ${list?.address?.state} ${list?.address?.country} ${list?.address?.pincode}`}</td>
                  <td>{list?.activeProfile.toString().toUpperCase()}</td>
                  <td onClick={(e)=> e.stopPropagation()}>
                    <button className={style.change} onClick={(e)=>handleViewButton(e,list)}>View</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div> 
          {modal &&
            <Modal
              open={modal}
              closeModal={setModal}
            >
              <ShopModal shopData={shopData} />
            </Modal>
          }
        </div>
      </div>
    </div>

  )
}

export default ShopBranchTable
