import React from 'react'
import styles from '../ReusableCoponents/ReusableStyles.module.scss'

const Button = ({title, color}) => {
  return (
    <div>
      {color === "primary" && <button className={styles.primaryBtn}>{title}</button>}
      {color === "secondary" && <button className={styles.secondaryBtn}>{title}</button>}
    </div>
  )
}

export default Button
