import React from "react";
import styles from "./Login.module.scss";
import loginImage from "../assets/images/loginImage.png";
import logoNew from "../assets/images/logoNew.png";
import { Link } from "react-router-dom";

const VerificationPage = () => {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.verificationLeftContainer}>
          <img src={logoNew} alt="" />
          <div className={styles.headingContainer}>
            <span className={styles.heading}>Verification Code</span>
            <span className={styles.subHeading}>
              Please enter the verification code we have sent you on your gmail
              account
            </span>
          </div>

          <div className={styles.verificationInputContainer}>
            <input type="text" inputmode="numeric" placeholder="" maxLength="1" />
            <input type="text" inputmode="numeric" placeholder="" maxLength="1" />
            <input type="text" inputmode="numeric" placeholder="" maxLength="1" />
            <input type="text" inputmode="numeric" placeholder="" maxLength="1" />
            <input type="text" inputmode="numeric" placeholder="" maxLength="1" />
            <input type="text" inputmode="numeric" placeholder="" maxLength="1" />
          </div>
          <Link to="/admin/finishPage" className={styles.primaryBtn}>
            Confirm
          </Link>
        </div>
        <div className={styles.rightContainer}>
          <img src={loginImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default VerificationPage;
