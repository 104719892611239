import React from "react";
import "./Table.scss";
import PageHeader from "../layout/pageHeader/PageHeader";
import { useNavigate } from "react-router-dom";
// import { getShopsByOwnerProfileId } from "../apiServices/Shop/ShopApiServices";
import ShopOwnerTableActions from "../pages/AdminPanelPages/shops/ShopOwnerTableActions";

function ShopOwnerTable({ shopOwnerColumnName, approvedProfilesData }) {

  const navigate = useNavigate()

  const shopDashboardNavigateHandler = (ownerId) => {

    navigate(`/admin/shops/shopBranches/${ownerId}`)
  }

  // console.log("approvedProfilesData", approvedProfilesData)

  return (
    <div className="DBdataGridBox">
      <PageHeader />
      <table className="DBdataGrid">
        <thead>
          <tr>
            {shopOwnerColumnName.map((col, index) => {
              return <th style={{textAlign: "center"}} id={col.id} key={index}>{col.name}</th>;
            })}
          </tr>
        </thead>

        <tbody>
          {approvedProfilesData?.map((approvedProfileData, index) => {
            return (
              <tr key={index}
                onClick={() => shopDashboardNavigateHandler(approvedProfileData?._id)}>
                <td>{index + 1} </td>
                <td>{approvedProfileData?.ownerName}</td>
                <td>{approvedProfileData?.authId?.email}</td>
                <td>{`${approvedProfileData?.address?.street || ''} ${approvedProfileData?.address?.city || ''} ${approvedProfileData?.address?.state || ''} ${approvedProfileData?.address?.country || ''} ${approvedProfileData?.address?.pincode || ''}`}</td>
                <td style={{textAlign: "center"}}>{approvedProfileData?.activeAccount?.toString().toUpperCase()}</td>
                <td style={{width: "12%"}} onClick={(e)=> e.stopPropagation()}> <ShopOwnerTableActions ownerId={approvedProfileData?._id} /> </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ShopOwnerTable;
