import React from 'react'

const Notification = () => {
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div className='notificationBox'>
        <h5>Notifications</h5>
        <ul className='notificationList'>
            <li className='notificationListItem'>
             <span className='notificationIcon'><i className="bi bi-person"></i></span>
             <span className='notificationText'>
                <h6>New shop registered</h6>
                <p>Just now</p>
             </span>
            </li>
            <li className='notificationListItem'>
             <span className='notificationIcon'><i className="bi bi-person"></i></span>
             <span className='notificationText'>
                <h6>New shop registered</h6>
                <p>Just now</p>
             </span>
            </li>
            <li className='notificationListItem'>
             <span className='notificationIcon'><i className="bi bi-person"></i></span>
             <span className='notificationText'>
                <h6>New shop registered</h6>
                <p>Just now</p>
             </span>
            </li>
            <li className='notificationListItem'>
             <span className='notificationIcon'><i className="bi bi-person"></i></span>
             <span className='notificationText'>
                <h6>New shop registered</h6>
                <p>Just now</p>
             </span>
            </li>
        </ul>
     </div>

     <div className='notificationBox'>
        <h5>Suggestions</h5>
        <ul className='notificationList'>
            <li className='notificationListItem'>
             <span className='notificationIcon'><i className="bi bi-person"></i></span>
             <span className='notificationText'>
                <h6>Improve user experience.</h6>
                <p>Just now</p>
             </span>
            </li>
            <li className='notificationListItem'>
             <span className='notificationIcon'><i className="bi bi-person"></i></span>
             <span className='notificationText'>
                <h6>Improve user experience.</h6>
                <p>Just now</p>
             </span>
            </li>
            <li className='notificationListItem'>
             <span className='notificationIcon'><i className="bi bi-person"></i></span>
             <span className='notificationText'>
                <h6>Improve user experience.</h6>
                <p>Just now</p>
             </span>
            </li>
            <li className='notificationListItem'>
             <span className='notificationIcon'><i className="bi bi-person"></i></span>
             <span className='notificationText'>
                <h6>Improve user experience.</h6>
                <p>Just now</p>
             </span>
            </li>
        </ul>
     </div>
    </div>
  )
}

export default Notification
