import React from "react";
import styles from '../ReusableStyles.module.scss'

const PricePackageInputBox = () => {
  return (
    <div>
      <select name="package" id="package" className={styles.priceSelectBox}>
        <option value="" disabled defaultValue hidden>
          Select Package
        </option>
        <option value="volvo">Volvo</option>
        <option value="saab">Saab</option>
        <option value="mercedes">Mercedes</option>
        <option value="audi">Audi</option>
      </select>
    </div>
  );
};

export default PricePackageInputBox;
