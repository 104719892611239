import React from 'react'
import styles from './ShopDashboardPage.module.scss'
import BarChart from '../../ReusableCoponents/BarChart/BarChart'

const BarChartContainer = () => {
    return (
        <div className={styles.barChartContainer}>
            <div className={styles.barChartHeader}>
                <span>Earning Timeline</span>
                <select name="days" id="days">
                    <option value="Last 7 days">Last 7 days</option>
                    <option value="Last 5 days">Last 5 days</option>
                    <option value="Last 3 days">Last 3 days</option>
                    <option value="Last 2 days">Last 2 days</option>
                </select>
            </div>
            <div className={styles.barChart}>
                <BarChart />
            </div>
        </div>
    )
}

export default BarChartContainer
