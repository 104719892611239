import React, { useEffect, useState } from 'react';
import {
  dashboardPhoto1,
  dashboardPhoto2,
  dashboardPhoto3,
} from '../../../assets';
import './dashboard.scss';
import ImageModal from '../../../modals/ImageModal/ImageModal';
import Modal from '../../../modals/Modal';
import { CrossSvgIcon, EyesSvgIcon, LocationSvgIcon, TickSvgIcon } from '../../../assets/svgIcons/svgIcons';
import Loader from '../../../ReusableCoponents/Loader/Loader';

const DashboardActions = ({approvalFunction, row}) => {

  const [loading, setLoading] = useState(false);
  
  return (
    <div className="actions">
      <LocationSvgIcon />
      <EyesSvgIcon />
      <div onClick={()=>approvalFunction(row, setLoading)}>
        {loading ? <Loader /> : <TickSvgIcon />}
      </div>
      <CrossSvgIcon />
    </div>
  );
};

const DashboardPhotos = ({ row }) => {

  const [modal, setModal] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null);

  const [imageUrl, setImageUrl] = useState([])

  const toggleModal = (url) => {
    setSelectedImage(url)
    setModal(!modal);
  };

  useEffect(() => {
    const aadharBackUrl = dashboardPhoto1 || (row?.aadharBack ? row?.aadharBack.secure_url : dashboardPhoto1)
    const aadharFrontUrl = dashboardPhoto1 || (row?.aadharFront ? row?.aadharFront.secure_url : dashboardPhoto2)
    const profilePhotoUrl = dashboardPhoto1 || (row?.profilePhoto ? row?.profilePhoto.secure_url : dashboardPhoto3)
    setImageUrl(
      [{ imgUrl: aadharBackUrl }, { imgUrl: aadharFrontUrl }, { imgUrl: profilePhotoUrl }]
    )
  }, [row])

  return (
    <div className="photos">
      {imageUrl?.map((img,index) => {
        return (
          <img
          key ={index}
           src={img?.imgUrl} alt="" onClick={() => toggleModal(img?.imgUrl)} />
        )
      })}
      <Modal
        open={modal}
        closeModal={setModal}
      >
        <ImageModal
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          images={imageUrl}
          setModal={setModal}
        />
      </Modal>
    </div>
  );
};

export { DashboardPhotos, DashboardActions };
