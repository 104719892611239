import React, { useState } from "react";
import styles from "./Login.module.scss";
import loginImage from "../assets/images/loginImage.png";
import logoNew from "../assets/images/logoNew.png";
import { Link } from "react-router-dom";
import { loginService } from "../apiServices/auth/AuthApiServices";
import { checkLoginData } from "../utils/loginPageHooks";
import { status } from "../constants/AuthConstants";
import { useDispatch } from "react-redux";
import { authActions } from "../store/auth/AuthSlice";
// import { getProfileByIdService } from "../apiServices/profile/ProfileServices";
// import { profileActions } from "../store/profile/ProfileSlice";
import { setTokenInLocalStorage } from "../utils/customHooks";

function LoginPage() {
  const dispatch = useDispatch();

  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });

  const loginHandler = () => {
    const tempLoginData = checkLoginData(loginData);
    console.log("tempLoginData", tempLoginData);

    if (tempLoginData) {
      loginService(tempLoginData).then((res) => {
        console.log("login res.data", res.data);
        if (res.status === status.success) {
          setTokenInLocalStorage("token", res.data.token);
          setTokenInLocalStorage("role", res.data.role);
          setTokenInLocalStorage("authId", res?.data?.authData?._id);
          dispatch(authActions.setTokenHandler(res.data.token));
          dispatch(authActions.setRoleHandler(res.data.role));
          dispatch(authActions.setAuthId(res?.data?.authData?._id));
          // getProfile(res?.data?.authData?._id)
        } else {
        }
      });
    }
  };

  const setFieldHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setLoginData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className={styles.outerContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.leftContainer}>
          <img src={logoNew} alt="" />
          <div className={styles.headingContainer}>
            <span className={styles.heading}>Welcome Back!</span>
            <span className={styles.subHeading}>
              Please login to your account
            </span>
          </div>

          <div className={styles.inputContainer}>
            <input
              type="text"
              name="username"
              value={loginData.username}
              onChange={setFieldHandler}
              placeholder="get@ziontutorial.com"
            />
            <input
              name="password"
              value={loginData.password}
              onChange={setFieldHandler}
              type="text"
              placeholder="Password"
            />
            <Link to="/admin/forgot" className={styles.forgot}>
              Forgot Password
            </Link>
          </div>

          <Link onClick={loginHandler} className={styles.primaryBtn}>
            Log In
          </Link>
        </div>
        <div className={styles.rightContainer}>
          <img src={loginImage} alt="" />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
