export const shopBranchTableColumnName = [
    {
      id: 1,
      name: "Photos",
    },
    {
      id: 2,
      name: "Shop Name",
    },
    {
      id: 3,
      name: "Email Address",
    },
    {
      id: 4,
      name: "Phone Number",
    },
    {
      id: 5,
      name: "Address",
    },
    {
      id: 6,
      name: "Verification Status",
    },
    {
      id: 7,
      name: "Actions",
    },
  ];