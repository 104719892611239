import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children }) => {
  const location = useLocation()

  const token = useSelector(state => state.auth.token);
  // return token ? children : <Navigate to="/admin/login" replace />;
  return token
    ? <>
      <Navigate to={`${location.pathname}`} />
      {children}
    </>
    : <Navigate to="/admin/login" replace />;
};

export default PrivateRoute;