import authSlice from './auth/AuthSlice'

import { configureStore } from '@reduxjs/toolkit'
import uiSlice from './ui/UiSlice'
import profileSlice from './profile/ProfileSlice'
import shopSlice from './shop/ShopSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    profile: profileSlice.reducer,
    ui: uiSlice.reducer,
    shop: shopSlice.reducer,
  },
})
