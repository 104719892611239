import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import InfiniteSlider from "../../ReusableCoponents/InfiniteSlider";
import { useState } from "react";
import {
  aboutImg1,
  aboutImg2,
  aboutImg3,
  aboutLftRyt1,
  aboutLftRyt2,
  aboutLftRyt3,
  appStoreBtn,
  bnrImg,
  contactImg,
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  logo,
  logoFooter,
  mobileFront,
  mobileLeft1,
  mobileLeft2,
  mobileRight1,
  mobileRight2,
  playStoreBtn,
  serviceIcon1,
  serviceIcon2,
  serviceIcon3,
  serviceIcon4,
  serviceIcon5,
  serviceIcon6,
} from "../../assets";
import { useNavigate } from "react-router";

function HomePage() {
  const navigate = useNavigate();

  const [speed, setSpeed] = useState(10000);

  let images = [gallery1, gallery2, gallery3, gallery4].map((image) => ({
    id: crypto.randomUUID(),
    image,
  }));


  return (
    <div className="App">
      {/* {[false, 'sm', 'md', 'lg', 'xl', 'xxl'].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
          <Container fluid>
            <Navbar.Brand href="#">Navbar Offcanvas</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Offcanvas
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="#action1">Home</Nav.Link>
                  <Nav.Link href="#action2">Link</Nav.Link>
                  <NavDropdown
                    title="Dropdown"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
               
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))} */}

      <Navbar collapseOnSelect expand="lg" className="sticky-top">
        <Container>
          <Navbar.Brand href="#home">
            <img className="" src={logo} alt="error" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" >
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link href="#about">About Us</Nav.Link>
              <Nav.Link href="#services">Services</Nav.Link>
              <Nav.Link href="#gallery">Gallery</Nav.Link>
              <Nav.Link href="#contact">Contact</Nav.Link>
              <Nav.Link onClick={() => navigate("/admin/dashboard")}>
                Login
              </Nav.Link>

              <Nav.Link className="mainBtn navBtn" href="#deets">
                Download App
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <section className="heroSection" id="home">
        <Container>
          <div className="banner">
            <div className="bannerLeft">
              <h1>Online Salon Booking Made Easy, Unleash Your Style!</h1>
              <p>
                Making customers hah6py and businesses healthy with world-class
                app and website design.
              </p>
              <Nav.Link className="mainBtn" href="#deets">
                Download App
              </Nav.Link>
            </div>
            <div className="bannerRight">
              <img src={bnrImg} alt="error" />
            </div>
          </div>
        </Container>
      </section>

      <section className="aboutSection" id="about">
        <Container>
          <div className="aboutOuter">
            <div className="aboutBox">
              <img src={aboutImg1} alt="error" />
              <h4 className="text-white">Convenience</h4>
              <p>
                Emphasize the ease of using the mobile app to book appointments,
                select stylists, and manage reservations.
              </p>
            </div>

            <div className="aboutBox">
              <img src={aboutImg2} alt="error" />
              <h4 className="text-white">Personalization</h4>
              <p>
                Highlight how the app allows users to create a personalized hair
                profile to receive tailored recommendations and services.
              </p>
            </div>

            <div className="aboutBox">
              <img src={aboutImg3} alt="error" />
              <h4 className="text-white">Exclusive Offers</h4>
              <p>
                Mention the exciting offers, discounts, and loyalty rewards
                available only to app users.
              </p>
            </div>
          </div>

          <div className="aboutLftRytOuter">
            <div className="aboutLftRytInner">
              <div className="aboutLftRytLeft">
                <img src={aboutLftRyt1} alt="error" />
              </div>
              <div className="aboutLftRytRight">
                <h4 className="text-white ">Introduction to the Salon</h4>
                <p>
                  At Hairssalon, we understand the frustrations of waiting for
                  hours at local salons. Our app is designed to offer a seamless
                  and time-efficient hair care solution.
                </p>
              </div>
            </div>

            <div className="aboutLftRytInner aboutRightMobile">
              <div className="aboutLftRytRight">
                <h4 className="text-white ">Team of Experts</h4>
                <p>
                  At Hairssalon, we understand the frustrations of waiting for
                  hours at local salons. Our app is designed to offer a seamless
                  and time-efficient hair care solution.
                </p>
              </div>
              <div className="aboutLftRytLeft">
                <img src={aboutLftRyt2} alt="error" />
              </div>
            </div>

            <div className="aboutLftRytInner">
              <div className="aboutLftRytLeft">
                <img src={aboutLftRyt3} alt="error" />
              </div>
              <div className="aboutLftRytRight">
                <h4 className="text-white ">Salon Features</h4>
                <p>
                  At Hairssalon, we understand the frustrations of waiting for
                  hours at local salons. Our app is designed to offer a seamless
                  and time-efficient hair care solution.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className="serviceSection" id="services">
        <Container>
          <div className="sectionHeading">
            <h2>Services</h2>
          </div>

          <div className="serviceOuter">
            <div className="serviceBox">
              <img src={serviceIcon1} alt="error" />
              <h4>Men's Grooming Services</h4>
              <p>
                At Hairssalon, we understand the frustrations of waiting for
                hours at local salons.{" "}
              </p>
            </div>

            <div className="serviceBox">
              <img src={serviceIcon2} alt="error" />
              <h4>Women's Grooming Services</h4>
              <p>
                At Hairssalon, we understand the frustrations of waiting for
                hours at local salons.{" "}
              </p>
            </div>

            <div className="serviceBox">
              <img src={serviceIcon3} alt="error" />
              <h4>Body Massages</h4>
              <p>
                At Hairssalon, we understand the frustrations of waiting for
                hours at local salons.{" "}
              </p>
            </div>

            <div className="serviceBox">
              <img src={serviceIcon4} alt="error" />
              <h4>Facials</h4>
              <p>
                At Hairssalon, we understand the frustrations of waiting for
                hours at local salons.{" "}
              </p>
            </div>

            <div className="serviceBox">
              <img src={serviceIcon5} alt="error" />
              <h4>Haircut and Styling</h4>
              <p>
                At Hairssalon, we understand the frustrations of waiting for
                hours at local salons.{" "}
              </p>
            </div>

            <div className="serviceBox">
              <img src={serviceIcon6} alt="error" />
              <h4>Bridal and Formal Updos</h4>
              <p>
                At Hairssalon, we understand the frustrations of waiting for
                hours at local salons.{" "}
              </p>
            </div>
          </div>
          <div id="gallery">
            <InfiniteSlider images={images} speed={speed} setSpeed={setSpeed} />
          </div>
        </Container>
      </section>

      <section className="appShowSection">
        <Container>
          <div className="appShowOuter">
            <img className="appShowImage1" src={mobileLeft2} alt="error" />
            <img className="appShowImage2" src={mobileLeft1} alt="error" />
            <img className="appShowImage3" src={mobileFront} alt="error" />
            <img className="appShowImage4" src={mobileRight1} alt="error" />
            <img className="appShowImage5" src={mobileRight2} alt="error" />
          </div>

          <div className="appDownloadBtn">
            <Nav.Link href="#deets">
              <img className="" src={playStoreBtn} alt="error" />
            </Nav.Link>
            <Nav.Link href="#deets">
              <img className="" src={appStoreBtn} alt="error" />
            </Nav.Link>
          </div>

          <div className="contactSection" id="contact">
            <div className="contactImg">
              <img className="" src={contactImg} alt="error" />
            </div>
            <div className="contactForm">
              <FloatingLabel
                controlId="floatingInput"
                label="Full name"
                className="mb-1 contactLable"
              >
                <Form.Control
                  className="contactInput"
                  type="name"
                  placeholder="name@example.com"
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingInput"
                label="Email address"
                className="mb-1 contactLable"
              >
                <Form.Control
                  className="contactInput"
                  type="email"
                  placeholder="name@example.com"
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingTextarea"
                label="Comments"
                className="mb-1 contactLable "
              >
                <Form.Control
                  className="contactInput"
                  as="textarea"
                  placeholder="Leave a comment here"
                />
              </FloatingLabel>
              <Nav.Link className="mainBtn BtnWhite" href="#deets">
                Download App
              </Nav.Link>
            </div>
          </div>
        </Container>
      </section>

      <footer>
        <Container>
          <div className="footerLogo">
            <Nav.Link href="#deets">
              <img className="" src={logoFooter} alt="error" />
            </Nav.Link>
          </div>

          <div className="footerLogo">
            <h6>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt.
            </h6>
          </div>

          <ul className="footerLinks">
            <li className="footerLinksItem">
              <Nav.Link href="#deets">About Us</Nav.Link>
            </li>
            <li className="footerLinksItem">
              <Nav.Link href="#deets">Services</Nav.Link>
            </li>
            <li className="footerLinksItem">
              <Nav.Link href="#deets">Gallery</Nav.Link>
            </li>
            <li className="footerLinksItem">
              <Nav.Link href="#deets">Contact</Nav.Link>
            </li>
            <li className="footerLinksItem">
              <Nav.Link href="#deets">Privacy Policy</Nav.Link>
            </li>
          </ul>

          <ul className="footerLinks footerLinksSocial">
            <li className="footerLinksItem">
              <Nav.Link href="#deets">
                <i className="bi bi-facebook"></i>
              </Nav.Link>
            </li>
            <li className="footerLinksItem">
              <Nav.Link href="#deets">
                <i className="bi bi-twitter"></i>
              </Nav.Link>
            </li>
            <li className="footerLinksItem">
              <Nav.Link href="#deets">
                <i className="bi bi-linkedin"></i>
              </Nav.Link>
            </li>
          </ul>
        </Container>
      </footer>
    </div>
  );
}

export default HomePage;
