import React from 'react'
import { searchIcon } from '../../assets'

const SearchBox = () => {
  return (
    <div className='box'>
      <input type="text" placeholder='Search shops, artists and customers' />
      <img src={searchIcon} alt=""/>
    </div>
  )
}

export default SearchBox
