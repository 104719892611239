import React from "react";
import "../tables/Table.scss";

const AppointmentsTable = ({ appointmentList, appointmentListColumnName }) => {
  return (
    <div className="DBdataGridBox">
      <table className="DBdataGrid">
        <thead>
          <tr>
            {appointmentListColumnName.map((col) => {
              return <th key={col.id}>{col.name}</th>;
            })}
          </tr>
        </thead>

        <tbody>
          {appointmentList.map((list) => {
            return (
              <tr key={list.id}>
                <td className="sr">{list.id}.</td>
                <td className="name">{list.name}</td>
                <td>{list.service}</td>
                {list.status==='Completed' && <td className="completed status">{list.status}</td>}
                {list.status==='Cancelled' && <td className="cancelled status">{list.status}</td>}
                {list.status==='In Process' && <td className="inProcess status">{list.status}</td>}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AppointmentsTable;
