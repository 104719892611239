
export const data = {
    labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    datasets: [
      {
        label: 'values',
        backgroundColor: 'rgba(59,115,88,1)',
        borderRadius: '4',
        barThickness: '20',
        hoverBackgroundColor: 'rgba(59,115,88,0.6)',
        data: [65, 59, 80, 81, 56, 55, 40],
      },
    ],
  };

 export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        // display: true,
        // text: 'Bar Chart Example',
      },
    },
  };