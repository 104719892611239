import React, { useEffect } from 'react';
import styles from './Modal.module.scss'

const Modal = ({ children, open, closeModal }) => {

  useEffect(()=>{
    //Add event listener for key press
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        closeModal(false)
      }
    }

    window.addEventListener("keydown", handleKeyPress)

    //Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress)
    }
  })

  return (
    <div className={styles.outerContainer}
      style={{ display: open ? "flex" : "none" }}
      onClick={() => closeModal(false)}>
      {children}
    </div>
  )
}

export default Modal
