import React from "react";
import styles from "./Login.module.scss";
import loginImage from "../assets/images/loginImage.png";
import logoNew from "../assets/images/logoNew.png";
import { Link } from "react-router-dom";

const ForgotPage = () => {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.forgotLeftContainer}>
          <img src={logoNew} alt="" />
          <div className={styles.headingContainer}>
            <span className={styles.heading}>Forgot Password</span>
            <span className={styles.subHeading}>
              Please enter your email ID
            </span>
          </div>

          <div className={styles.inputContainer}>
            <input type="text" placeholder="get@ziontutorial.com" />
          </div>
          <Link to="/admin/verification" className={styles.primaryBtn}>
            Next
          </Link>
        </div>
        <div className={styles.rightContainer}>
          <img src={loginImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ForgotPage;
