import React, { useCallback, useEffect, useState } from 'react'
import styles from './ProfilePreview.module.scss';
import {
  ProfileModalImage,
} from '../../assets'
import { Camera } from '../../assets/svgIcons/svgIcons';
import { editProfileByIdService, getProfileByIdService } from '../../apiServices/profile/ProfileServices'
import { useSelector, useDispatch } from 'react-redux'
import { profileActions } from '../../store/profile/ProfileSlice'
import { status } from '../../constants/AuthConstants'
import CallSVGIcon from '../../assets/svgIcons/CallSVGIcon/CallSVGIcon';
import CategorySVGIcon from '../../assets/svgIcons/CategorySVGIcon/CategorySVGIcon';
import LocationSVGIcon from '../../assets/svgIcons/LocationSVGIcon/LocationSVGIcon';
import EmailSVGIcon from '../../assets/svgIcons/EmailSVGIcon/EmailSVGIcon';
import Address from './Address';

const ProfilePreview = () => {

  const dispatch = useDispatch()

  const authId = useSelector((state) => state.auth.authId)
  const profileData = useSelector((state) => state.profile.profileData)

  const [isEditable, setIsEditable] = useState(false)
  const [editedProfileData, setEditedProfileData] = useState('')

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditedProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const getProfile = useCallback((id) => {
    getProfileByIdService(id)
      .then((res) => {
        // console.log("Profile Preview", res)
        if (res.status === status.success) {
          dispatch(profileActions.setProfileData(res.data))
        }
      })
  }, [dispatch])
  // console.log("Profile data", profileData)

  useEffect(() => {
    getProfile(authId)
  }, [getProfile, authId])

  useEffect(() => {
    if (profileData) {
      setEditedProfileData(profileData)
    }
  }, [profileData])

  const editProfileHandler = () => {
    setIsEditable(true)
  }

  const saveProfileHandler = () => {
    // console.log("EditedProfileData is : ", editedProfileData)
    editProfileByIdService(profileData._id, editedProfileData).then((res) => {
      // console.log("res", res)
      if (res.status === status.success) {
        setIsEditable(false)
        getProfile(profileData.authId)
      }
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.heading}>Profile</div>

      <div className={styles.name}>
        <div className={styles.imageContainer}>
          <img src={ProfileModalImage} alt='' className={styles.profileImg} />
          <div className={styles.changePhoto}>
            <Camera />
          </div>
        </div>
        {isEditable
          ? <input type="text"
            value={editedProfileData.name}
            onChange={handleChange}
            name="name" />
          : <h3>{editedProfileData?.name}</h3>
          }
      </div>

      <div className={styles.details}>
        <div className={styles.row}>
          <EmailSVGIcon />
          <span>{editedProfileData?.email}</span>
        </div>
        <div className={styles.row}>
          <CallSVGIcon />
          <span>{profileData?.mobileNumber}</span>
        </div>
        <div className={styles.row}>
          <CategorySVGIcon />
          {
            isEditable
              ?
              <select
                value={editedProfileData.sex}
                placeholder='sex'
                onChange={handleChange}
                name="sex">
                <option value="MALE">MALE</option>
                <option value="FEMALE">FEMALE</option>
              </select>
              :
              <span>{editedProfileData?.sex}</span>}
        </div>
        <div className={styles.row}>
          <LocationSVGIcon />
          {
            isEditable
              ?
              <Address
                address={editedProfileData?.address}
                setEditedProfileData={setEditedProfileData} />
              :
              <span>{`${profileData?.address?.street} ${profileData?.address?.city} ${profileData?.address?.state} ${profileData?.address?.country} ${profileData?.address?.pincode}`}</span>
          }
        </div>
      </div>

      {isEditable ? (<button onClick={saveProfileHandler}>Save Profile</button>
      ) : (
        <button onClick={editProfileHandler}>Edit Profile</button>
      )}
    </div>
  )
}

export default ProfilePreview
