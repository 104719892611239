import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { data, options } from '../../constants/PieChartConstants';

ChartJS.register(ArcElement, Title, Tooltip, Legend);

const PieChart = () => {
  return <Pie data={data} options={options} />;
};

export default PieChart;