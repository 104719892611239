import { checkNumberWith10Digit, isValidEmail } from "./Validations"

export const checkLoginData = (data) => {
    console.log()
    if (checkNumberWith10Digit(data.username)) {
        return { mobileNumber: data.username, password: data.password }
    }
    if (isValidEmail(data.username)) {
        return { email: data.username, password: data.password }
    }
    return null
}