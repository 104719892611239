import axios from 'axios';
import { store } from '../store/store';

const instance = axios.create({
  baseURL: 'https://hairsalon.azurewebsites.net/',
  // baseURL: 'http://192.168.1.6:1234/',
});

instance.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (!(config.data instanceof FormData)) {
      config.headers['Content-Type'] = 'application/json';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
});

export default instance;
