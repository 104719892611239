import { useState, useEffect, useCallback } from 'react';
import './dashboard.scss';
import AdminHeader from '../../../layout/adminheader/AdminHeader';
import {
  DashboardLowerTable,
  DashboardTable,
} from '../../../tables/DashboardTable';
import {
  appointmentsColumnName,
  appointmentsList,
  dashboardColumnName,
} from '../../../constants/DashboardConstants';
import { getShopOwnerNewProfiles } from '../../../apiServices/dashboard/DashboardServices';
import Loader from '../../../ReusableCoponents/Loader/Loader';

function Dashboard() {

  const [profilesData, setProfilesData] = useState([])
  const [loading, setLoading] = useState(true)  

  const getProfileData = useCallback(() => {
    setLoading(true)
    getShopOwnerNewProfiles(false)
      .then((res) => {
        // console.log("dashboard profiles", res.data)
        if (res?.data.length > 0) {
          setProfilesData(res?.data)
        }
        setLoading(false)
      })
    
  }, [])

  useEffect(() => {
    setLoading(true)
    getProfileData()
  }, [getProfileData])

  // console.log(profileData)

  return (
    <div className="middleData">
      <AdminHeader title="Dashboard" />

      <div className="dashboardData">
        <div className="dashboardMain">
          <div className="dashboardStats">
            <div className="dashboardStatsBox">
              <span>Men’s Salon</span>
              <span>721K</span>
            </div>
            <div className="dashboardStatsBox">
              <span>Women’s Salon</span>
              <span>367K</span>
            </div>
            <div className="dashboardStatsBox">
              <span>Unisex's Salon</span>
              <span>1,156K</span>
            </div>
            <div className="dashboardStatsBox">
              <span>New Registered</span>
              <span>239K</span>
            </div>
          </div>
        </div>

        <div className="tables">
          <div className="shopApproval">
            <div className="heading">
              <div>
                <span>Shop Approval</span>
                {loading && <Loader />}
              </div>
              <button>View All</button>
            </div>
            <DashboardTable
              dashboardColumnName={dashboardColumnName}
              profilesData={profilesData}
              getProfileData={getProfileData}
            />
          </div>
          <div className="shopApproval">
            <div className="heading">
              <span>List of Appointments</span>
              <button>View All</button>
            </div>
            <DashboardLowerTable
              appointmentsColumnName={appointmentsColumnName}
              appointmentsList={appointmentsList}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
