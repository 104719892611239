import React from 'react'
import './Package.scss'
import { packagei } from '../../../assets'
import AdminHeader from '../../../layout/adminheader/AdminHeader';

function Package() {
  return (
    <div className='midData'>
        <AdminHeader title="ABC Barber Shop" />
        <div className='packageMain'>
          <div className='packageBox'>
          <img src={packagei} alt='error'/>
          <h5>Package One</h5>
          <ul className='packageDetail'>
            <li className='packageDetailItem'>
                <span className='packageDetailIcon'><i className="bi bi-percent"></i></span>
                <span>
                    <p>Price</p>
                    <h6>₹400</h6>
                </span>
            </li>
            <li className='packageDetailItem'>
                <span className='packageDetailIcon'><i className="bi bi-toggle2-on"></i></span>
                <span>
                    <p>Status</p>
                    <h6>Active</h6>
                </span>
            </li>
            <li className='packageDetailItem'>
                <span className='packageDetailIcon'><i className="bi bi-list"></i></span>
                <span>
                    <p>Description</p>
                    <h6>fvgfn fgmbkjmk fgmbkfmkm smgkdmnknm mjkfnmkdm</h6>
                </span>
            </li>
          </ul>
          </div>
          <div className='packageBox'>
          <img src={packagei} alt='error'/>
          <h5>Package One</h5>
          <ul className='packageDetail'>
            <li className='packageDetailItem'>
                <span className='packageDetailIcon'><i className="bi bi-percent"></i></span>
                <span>
                    <p>Price</p>
                    <h6>₹400</h6>
                </span>
            </li>
            <li className='packageDetailItem'>
                <span className='packageDetailIcon'><i className="bi bi-toggle2-on"></i></span>
                <span>
                    <p>Status</p>
                    <h6>Active</h6>
                </span>
            </li>
            <li className='packageDetailItem'>
                <span className='packageDetailIcon'><i className="bi bi-list"></i></span>
                <span>
                    <p>Description</p>
                    <h6>fvgfn fgmbkjmk fgmbkfmkm smgkdmnknm mjkfnmkdm</h6>
                </span>
            </li>
          </ul>
          </div>
          <div className='packageBox'>
          <img src={packagei} alt='error'/>
          <h5>Package One</h5>
          <ul className='packageDetail'>
            <li className='packageDetailItem'>
                <span className='packageDetailIcon'><i className="bi bi-percent"></i></span>
                <span>
                    <p>Price</p>
                    <h6>₹400</h6>
                </span>
            </li>
            <li className='packageDetailItem'>
                <span className='packageDetailIcon'><i className="bi bi-toggle2-on"></i></span>
                <span>
                    <p>Status</p>
                    <h6>Active</h6>
                </span>
            </li>
            <li className='packageDetailItem'>
                <span className='packageDetailIcon'><i className="bi bi-list"></i></span>
                <span>
                    <p>Description</p>
                    <h6>fvgfn fgmbkjmk fgmbkfmkm smgkdmnknm mjkfnmkdm</h6>
                </span>
            </li>
          </ul>
          </div>
          <div className='packageBox'>
          <img src={packagei} alt='error'/>
          <h5>Package One</h5>
          <ul className='packageDetail'>
            <li className='packageDetailItem'>
                <span className='packageDetailIcon'><i className="bi bi-percent"></i></span>
                <span>
                    <p>Price</p>
                    <h6>₹400</h6>
                </span>
            </li>
            <li className='packageDetailItem'>
                <span className='packageDetailIcon'><i className="bi bi-toggle2-on"></i></span>
                <span>
                    <p>Status</p>
                    <h6>Active</h6>
                </span>
            </li>
            <li className='packageDetailItem'>
                <span className='packageDetailIcon'><i className="bi bi-list"></i></span>
                <span>
                    <p>Description</p>
                    <h6>fvgfn fgmbkjmk fgmbkfmkm smgkdmnknm mjkfnmkdm</h6>
                </span>
            </li>
          </ul>
          </div>
        </div>
    </div>
  )
}

export default Package