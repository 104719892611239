import React from 'react'
import Artist from '../../pages/AdminPanelPages/artists/Artist'

const ShopArtists = () => {
  return (
    <div>
      <Artist />
    </div>
  )
}

export default ShopArtists
