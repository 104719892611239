import React from "react";
import "./Artist.scss";
import AdminHeader from "../../../layout/adminheader/AdminHeader";
import ArtistsTable from "../../../tables/ArtistsTable";
import { artistsColumnName, artistsList } from "../../../constants/ArtistsConstants";

function Artist() {
  return (
    <div className="middleData">
      <AdminHeader title="Artists" />
        <ArtistsTable artistsColumnName={artistsColumnName} artistsList={artistsList} />
    </div>
  );
}

export default Artist;
