import React from "react";
import AdminHeader from "../../layout/adminheader/AdminHeader";
import styles from "./ShopReview.module.scss";
import ShopProfile from "./ShopProfile";
import ReviewCard from "./ReviewCard";

const ShopReviews = () => {
  return (
    <div className="middleData">
      <AdminHeader title="Reviews" />
      <div className={styles.outerContainer}>
        <span className={styles.heading}>Review List</span>
        <div className={styles.reviewContainer}>
          <ShopProfile />
          <ReviewCard />
          <ReviewCard />
          <ReviewCard />
        </div>
      </div>
    </div>
  );
};

export default ShopReviews;
