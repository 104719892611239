import React from 'react'
import styles from "./shopBranch.module.scss";
import BranchCard from "./BranchCard";
// import MenuSVGIcon from '../../assets/svgIcons/MenuSVGIcon/MenuSVGIcon'

const BranchContainer = () => {

  return (
    <div className={styles.container}>
        <div className={styles.upperHeadings}>
          {/* <span>Branch List</span> */}
          {/* <span onClick={()=>setMenuOptionIcon(false)}>
            <MenuSVGIcon />
          </span> */}
        </div>
        <div className={styles.childCardContainer}>
          <BranchCard />
          <BranchCard />
        </div>
      </div>
  )
}

export default BranchContainer
