import React from 'react';
import './Table.scss';
import {
  DashboardPhotos,
  DashboardActions,
} from '../pages/AdminPanelPages/dashboard/DashboardActions';
import { approveShopOwnerProfile } from '../apiServices/dashboard/DashboardServices';



function DashboardTable({ dashboardColumnName, profilesData, getProfileData }) {

  // console.log("profilesData", profilesData)

  const approvalFunction = (profileData, setLoading) => {

    setLoading(true);

    // console.log("Profile Data is ",profileData)
    const tempData = { aadharVerifiedStatus: true }
    approveShopOwnerProfile(profileData?._id, tempData)
      .then((res) => {
        // console.log("Response", res)
        if (res.status === 'success') {
          getProfileData()
          setLoading(false)
        }
      }).catch((err) => {
        console.error(err)
      })
  }

  return (
    <div className="DBdataGridBox">
      <table className="DBdataGrid">
        <thead>
          <tr>
            {dashboardColumnName?.map((col) => {
              return <th id={col.id} key={col.id}>{col.name}</th>
            })}
          </tr>
        </thead>

        <tbody>
          {profilesData?.map((row, index) => {
            return (
              <tr key={index}>
                <td>
                  <DashboardPhotos row={row} />
                </td>
                <td>{row?.ownerName ?? ''}</td>
                <td>{row?.aadhar ?? ''}</td>
                <td>{row?.number ?? ''}</td>
                <td>
                  {/* {row?.address ?? ''} */}
                </td>

                <td>{row?.category ?? ''}</td>
                <td>
                  <DashboardActions approvalFunction={approvalFunction} row={row} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
}

function DashboardLowerTable({ appointmentsColumnName, appointmentsList }) {
  return (
    <div className="DBdataGridBox">
      <table className="DBdataGrid">
        <thead>
          <tr>
            {appointmentsColumnName.map((col) => {
              return <th id={col.id} key={col.id}>{col.name}</th>;
            })}
          </tr>
        </thead>
 
        <tbody>
          {appointmentsList.map((list) => {
            return (
              <tr id={list.id} key={list.id}>
                <td style={{ textAlign: 'center' }}>{list.id}.</td>
                <td>{list.shop}</td>
                <td>{list.customer}</td>
                <td style={{ textAlign: 'center' }}>{list.mobile}</td>
                <td>{list.artist}</td>
                <td>{list.location}</td>
                {/* <td>{list.category}</td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export { DashboardTable, DashboardLowerTable };
