import React from 'react'
import Customers from '../../pages/AdminPanelPages/customers/Customers'

const ShopCustomers = () => {
  return (
    <div>
      <Customers />
    </div>
  )
}

export default ShopCustomers
