import React from 'react'
import styles from './ShopDashboardPage.module.scss'
import ArtistsTable from './../../tables/ArtistsTable'
import { artistsColumnName, artistsList } from '../../constants/ArtistsConstants'

const ArtistsContainer = () => {
  return (
    <div className={styles.artistContainer}>
      <div className={styles.heading}>
        <h3>Artists</h3>
        <button>View all</button>
      </div>
      <div className={styles.artistsTable}>
        <ArtistsTable artistsColumnName={artistsColumnName} artistsList={artistsList} showHeader={false} />
      </div>
    </div>
  )
}

export default ArtistsContainer
