import { useEffect, useState } from "react";
import { dashboardPhoto1, dashboardPhoto2 } from "../../assets";
import Modal from "../../modals/Modal";
import ImageModal from "../../modals/ImageModal/ImageModal";


const ShopBranchesPhotos = ({ shopData }) => {

    const [modal, setModal] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null);
  
    const [imageUrl, setImageUrl] = useState([])
  
    const toggleModal = (url) => {
      setSelectedImage(url)
      setModal(!modal);
    };
  
    useEffect(() => {
      const shopFrontPhoto = shopData?.shopFrontPhoto || dashboardPhoto1
      const shopInnerPhoto = shopData?.shopInnerPhoto || dashboardPhoto2
      const shopPhoto = shopData?.shopPhoto || dashboardPhoto1
      const shopPhotoWithOwner = shopData?.shopPhotoWithOwner || dashboardPhoto2

      setImageUrl(
        [{ imgUrl: shopFrontPhoto }, { imgUrl: shopInnerPhoto }, { imgUrl: shopPhoto }, { imgUrl: shopPhotoWithOwner }]
      )
    }, [shopData])
  
    return (
      <div className="photos">
        {imageUrl?.map((img,index) => {
          return (
            <img
            key ={index}
             src={img?.imgUrl} alt="" onClick={() => toggleModal(img?.imgUrl)} />
          )
        })}
        <Modal
          open={modal}
          closeModal={setModal}
        >
          <ImageModal
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            images={imageUrl}
            setModal={setModal}
          />
        </Modal>
      </div>
    );
  };
  
  export default ShopBranchesPhotos;
  