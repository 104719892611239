export const data = {
    labels: ['Completed', 'In progress', 'Pending', 'Cancelled'],
    datasets: [
      {
        label: 'Dataset',
        data: [38.6, 30.8, 22.5, 8.1],
        backgroundColor: ['#CBE8DA', '#B1E3FF', '#A1E3CB', '#FFDEDE'],
        hoverOffset: 4,
      },
    ],
  }

  export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        align: 'center', // Align the legend to the center
        labels: {
          boxWidth: 15, // Adjust the box width for the legend
        },
      },

      elements: {
        arc: {
          borderWidth: 0, // Remove border from arcs
        },
      },
      layout: {
        padding: {
          top: 0,
          bottom: 0,
          boxWidth: 0, // Adjust the right padding as needed
        },
      },
    },
    radius: '90%', // Set the radius of the pie chart
    cutout: '50%', // Make a disc in place of chart
  };