import { createSlice } from "@reduxjs/toolkit";
// import { constRightSidebarToggle } from "../../constants/Constants";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    rightSidebarPath: '' ,
  },
  reducers: {
    setRightSidebarHandler(state, action) {
      state.rightSidebarPath = action.payload
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;
